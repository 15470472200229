$ ->
	$('body').on 'click', '[js-toggle-button]', (e)->
		e.preventDefault()
		val = $(e.target).attr('js-toggle-button')
		$("[js-toggle-container='#{val}']").toggleClass 'show'

	$('[js-auto-carousel]').each ->
		$that = $(this)
		currentSlide = 1
		slideCount = $that.find('li').length
		intervalInSeconds = 8
		setInterval ->
			nextSlide = (currentSlide % slideCount) + 1
			$that.removeClass("show-#{currentSlide}")
			$that.addClass("show-#{nextSlide}")
			currentSlide = nextSlide
		, intervalInSeconds * 1000

	$lazyLoadData = null



	$('[js-slideshow]').each ->
		$this = $(this)
		$images = $this.find('[js-images] > * > *')
		count = $images.length
		interval = $this.attr('js-slideshow') * 1000
		timer = null
		active = -1
		#console.log count, interval
		setNext = ()->
			$images.eq(active).removeClass 'show'
			active = (active+1) % count
			$images.eq(active).addClass 'show'
			setTimer()
			
		setTimer = ()->
			timer = setTimeout ()->
					setNext()
				,
					interval
		clearTimer = ()->
			if timer then clearTimeout timer
		setNext()
		# $this.on 'mouseover', ()->
		# 	clearTimer()
		# $this.on 'mouseout', ()->
		# 	clearTimer()
		# 	setTimer()



	$('[js-carousel]').each ->
		$rootContainer = $(this)
		partialCache = {}
		returnProjectDirection = history.state

		prepPartial = ($page)->
			obj = {}
			$c = $page.find('[js-carousel]')
			obj.$container = $c
			obj.title = $page.find('title').text()
			obj.$carousel = $c.find('[js-images] ul')
			obj.$slides = $c.find('li')
			obj.$slideCount = $c.find('[js-slidecount]')
			obj.totalSlides = obj.$slides.length
			obj.currentSlide = 1
			obj.autoAdvance = $c.attr('js-carousel-auto-advance')
			obj.$prevSlideButton = $c.find('[js-prev-image]')
			obj.$nextSlideButton = $c.find('[js-next-image]')
			obj.$prevProjectButton = $c.find('[js-prev-project]')
			obj.$nextProjectButton = $c.find('[js-next-project]')

			bindButtons obj
			$rootContainer.after obj.$container
			return obj

		bindButtons = (partial)->
			partial.$prevSlideButton.on 'click', (e)->
				e.preventDefault()
				setSlide (currentPartial.currentSlide - 1) or currentPartial.totalSlides

			partial.$nextSlideButton.on 'click', (e)->
				e.preventDefault()
				setSlide ((currentPartial.currentSlide) % currentPartial.totalSlides) + 1

			if partial.autoAdvance and partial.autoAdvance > 0
				setInterval ->
					setSlide partial.currentSlide + 1
				, partial.autoAdvance * 1000

		currentPartial = prepPartial $('html')
		partialCache[window.location.href] = currentPartial


		$(window).on 'popstate', (e)->
			console.log history.state, returnProjectDirection
			if returnProjectDirection
				e.preventDefault()
				changeProject location.href, returnProjectDirection
			returnProjectDirection = history.state

		setSlide = (slide)->

			currentPartial.$slides.eq(currentPartial.currentSlide-1).removeClass('show')
			currentPartial.$slides.eq(slide-1).addClass('show')
			currentPartial.currentSlide = slide
			currentPartial.$slideCount.html "<b>#{slide}</b> / #{currentPartial.totalSlides}"

		setSlide 1

		getPartial = (url, callback)->
			if partialCache[url]
				#console.log "from cache: #{url}"
				callback partialCache[url]
			else
				$.ajax
					url: url
					success: (data)->
						console.log "from ajax: #{url}"
						partial = prepPartial $(data)
						partialCache[url] = partial
						$lazyLoadData = partial.$container.find('[js-lazy-load]')
						lazyLoader 0
						callback partial
					error: ->
						console.log "error retrieving #{url}"

		changeProject = (url, direction, callback)->	
			getPartial url, (partial)->
				partial.$container.css
					position: 'absolute'
					top: 0
					left: 0
					width: currentPartial.$container.width()
					height: currentPartial.$container.outerHeight()
					opacity: 0
					display: 'block'

				if direction == 'next'
					returnProjectDirection = 'prev'
					TweenLite.set partial.$container, y: '100%'
					TweenLite.to currentPartial.$container, 1.3, y: '-100%', ease: Power2.easeInOut
				else
					returnProjectDirection = 'next'
					TweenLite.set partial.$container, y: '-100%'
					TweenLite.to currentPartial.$container, 1.3, y: '100%', ease: Power2.easeInOut
					
				TweenLite.to currentPartial.$container, 0.5, opacity: 0
				TweenLite.to partial.$container, 1.3, y: '0%'

				TweenLite.to partial.$container, 0.8, opacity: 1, delay: 0.5, onComplete: ->
					currentPartial.$container.css
						display: 'none'
					partial.$container.css
						position: ''
						top: ''
						left: ''
						width: ''
						height: ''
						opacity: ''
					currentPartial = partial

				callback(partial) if callback
					



	$lazyLoadData = $('[js-lazy-load]')
	lazyLoader = (slide)->
		$slide = $lazyLoadData.eq(slide)
		url = if $slide.is('a') then $slide.attr('href') else $slide.attr('js-lazy-load')
		$slide.removeAttr 'href'
		$img = $('<img>')
		$originalImg = $slide.find('img')
		$img.css
			opacity: 0
			zIndex: 10
			position: 'absolute'
			top: 0
			left: 0
			# maxWidth: '100%'
			# maxHeight: '100%'
		$img.on 'load', ->
			#console.log "Slide #{slide} has loaded"
			$slide.removeAttr 'js-lazy-load'
			$slide.append $img
			$img.animate
				opacity: 1
			, 2000
			, ->
				$img.css
					position: ''
					top: ''
					left: ''
					width: ''
					height: ''
				$originalImg.remove()

			if slide < $lazyLoadData.length-1
				lazyLoader slide+1
		$img.attr 'src', url

	if $lazyLoadData.length
		lazyLoader 0

	$('[js-social]').each ->
		$that = $(this)
		$label = $('<span>')
		$that.append $label
		$that.find('a').hover ->
			$label.text $(this).text()
		, ->
			$label.text ''

	if $('[js-project-grid]').length
		category = null
		$grid = $('[js-project-grid]')
		baseURL = $('li#menu-item-586 > a').attr('href')
		$ul = $grid.find('> ul')
		categories = {}
		cats = [
			['architecture', 'Architecture'],
			['interiors', 'Interiors'],
			['progress', 'Progress']
		]
		for cat in cats
			$link = $("a[href$='/projects/#{cat[0]}']")
			categories[cat[0]] = 
				$link: $link
				$li: $link.parent()
				url: "#{baseURL}#{cat[0]}"
				title: "#{cat[1]} | Projects | Tyler Engle"
				projects: []

		categories['all'] = 
			$link: $("a[href$='/projects/']")
			url: baseURL
			title: "Projects | Tyler Engle"
			projects: []

		for cat, obj of categories
			obj.$link.on 'click', {obj:obj}, (e)->
				e.preventDefault()
				obj = e.data.obj
				window.history.pushState {url: obj.url}, obj.title, obj.url
				updateFromUrl obj.url

		updateGrid = (newCat)->
			#console.log 'updateGrid'
			unless category == newCat
				$ul.animate {opacity:0}, 100, ()->
					$ul.detach()
					category = newCat
					i = 1
					for project in categories['all'].projects
						project.$a.removeClass('show')
						project.$el.detach()
					if category == 'all'
						for cat, obj of categories
							unless cat == 'all'
								obj.$li.removeClass 'current-menu-item'
						for project in obj.projects
							if 'progress' not in project.cat
								$ul.append project.$el
								timerGridShow(project, 'show', i++)
					else
						for cat, obj of categories
							#console.log 'asdff', category, cat, category == cat, obj
							if cat == category
								obj.$li.addClass 'current-menu-item'
								for project in obj.projects
									$ul.append project.$el
									timerGridShow(project, 'show', i++)
							else
								obj.$li?.removeClass 'current-menu-item'
						# for cat, obj of categories
						# 	if cat != category and cat != 'all'
						# 		for project in obj.projects
						# 			project.$el.detach()
						# 			project.$a.removeClass('show')
									#$ul.append project.$el
									#timerGridShow(project, 'faded', i++)
					$ul.animate {opacity:1}, 200
					$grid.append $ul

		updateFromUrl = (url)->
			el = document.createElement('a');
			el.href = url;
			path = el.pathname.replace(/(^\/|\/$)/g, '').split('/')
			cat = 'all'
			if path.length >= 2 and categories[path[path.length-1]]
				cat = path[path.length-1]
			updateGrid cat

		window.onpopstate = (e)->
			#console.log 'popstate', e
			updateFromUrl window.location.pathname

		window.onpushstate = (e)->
			#console.log 'pushstate', e
			updateFromUrl window.location.pathname

		$ul.find('> li').each (index)->
			$this = $(this)
			project = {}
			project.$el = $this
			project.$a = $this.find '> a'
			project.cat = []
			
			string = project.$a.attr('js-project-category')

			for string in string.trim().split(' ')
				s = string.split('|')
				cat = s[0]
				project.all_sort = if s[1] && !isNaN(s[1]) then parseInt(s[1]) else 99
				project.cat_sort = if s[2] && !isNaN(s[2]) then parseInt(s[2]) else 99
				project.cat.push(cat)
				categories[cat].projects.push(project)
				categories['all'].projects.push(project)


		
			
		categories['all'].projects.sort (cat1, cat2)-> return cat1.app_sort - cat2.app_sort


		for cat in cats
			categories[cat[0]].projects.sort (cat1, cat2)-> return cat1.cat_sort - cat2.cat_sort


		
		# categories['architecture'].projects = categories['architecture'].projects.filter(Object)
		# categories['interiors'].projects = categories['interiors'].projects.filter(Object)
		# categories['progress'].projects = categories['progress'].projects.filter(Object)

		timerGridShow = (project, klass, i)->
			foo = (project, klass, i)->
				setTimeout ()->
						project.$a.removeClass('show').addClass(klass)
					,
						i*50
			foo(project, klass, i)

		updateFromUrl window.location.pathname

	#$('[js-match-height] > *').matchHeight()

	doLayout = ()->
		$body = $('body')
		width = window.innerWidth
		height = window.innerHeight
		$body.removeClass('layout-sm-tall layout-sm-wide layout-md layout-lg layout-xl')
		if width < 600 && height > width * 1.3
			$body.addClass('layout-sm-tall')
		else if width < 880 && height < width * 0.8
			$body.addClass('layout-sm-wide')
		else if width < 880
			$body.addClass('layout-md')
		else if width >= 1200 && height >= 720
			$body.addClass('layout-xl')		
		else
			$body.addClass('layout-lg')

	window.onresize = doLayout
	doLayout()
			

		




		
		













